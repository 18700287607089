<template>
	<div>
		
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="医生信息" name="doctor">
				
				<el-form ref="queryForm" size="small" :inline="true"  label-width="68px">
				  <el-form-item label="名称" prop="keys">
					  <el-input v-model="keys"  placeholder="请输入名称" clearable  @keyup.enter.native="search" />
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
				  </el-form-item>
				</el-form>
				
				<el-table :v-loading="loading" :data="doctorList" style="width: 100%">
					  <el-table-column  label="医生" prop="name" width="150" />
					  <el-table-column  label="医院" prop="patient_name" width="120" >
						  <template slot-scope="scope">					
							<div>{{getDoctorTitle(scope.row.doctorTitle)}}</div>
							<div >{{scope.row.hospitalName}}</div>
						  </template>
					  </el-table-column>
					  <el-table-column  label="擅长" prop="field"  />
					  <el-table-column  label="操作" prop="patient_name" width="120" >
					  		<template slot-scope="scope">					
								 <el-button type="primary"  @click="selDoctorDate(scope.row)"  size="mini">预约</el-button>
					  		</template>
					  </el-table-column>
				</el-table>
				
				
				
		
			</el-tab-pane>
			<el-tab-pane label="医院信息" name="hospital">
				<el-form ref="queryForm" size="small" :inline="true"  label-width="68px">
				  <el-form-item label="名称" prop="keys">
					  <el-input v-model="keys"  placeholder="请输入名称" clearable  @keyup.enter.native="search" />
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
				  </el-form-item>
				</el-form>
				
				<el-table :v-loading="loading" :data="hospitalList" style="width: 100%">
					  <el-table-column  label="医院" prop="hospitalName" width="150" />
				
					  <el-table-column  label="简介" prop="introduction"  />
					  <el-table-column  label="操作" prop="patient_name" width="120" >
					  		<template slot-scope="scope">					
								 <el-button type="primary"  @click="selHospital(scope.row)"  size="mini">预约</el-button>
					  		</template>
					  </el-table-column>
				</el-table>
				
				
				
			</el-tab-pane>
			
			
		
		</el-tabs>
		
		
		
		<timePanel  :doctorId="selDoctorId"
		:isWindows="isWindows"  :date="dateSelTime"
		:dataList="timeList"
		 :titleWindows="titleWindows" @openWindows="openWindows"  ></timePanel>
		
		<datePanel :doctorId="selDoctorId"  
		:isWindows="isDoctorWindows"
		 :titleWindows="titleWindows" @openWindows="openDoctorWindows" ></datePanel>
		 
		 <doctorPanel :hospitalId="selHospitalId"  :type="'transfer'"
		 :isWindows="isHospitalWindows"  @selDoctor="selDoctor"
		  :titleWindows="titleWindows" @openWindows="openHospitalWindows" ></doctorPanel>
		
		
		<el-dialog title="选择到院时间" :visible.sync="openTimeWindows"  width="600px" append-to-body>
			<div class="block">
			    <!-- <span class="demonstration"></span> -->
			    <el-date-picker
			      v-model="transferDate"
			      type="date"
			      placeholder="选择日期">
			    </el-date-picker>
			
			</div>
			  
			<div style="margin: 10px; text-align: right;">
				<el-button type="primary" @click="onDataSubmit" size="small">预约</el-button>
				
			</div>
			   
			
		</el-dialog>
			
	
	</div>
</template>

<script>
	
	
	import datePanel  from '@/components/timeWindows/date.vue'
	
	import doctorPanel  from '@/components/timeWindows/doctor.vue'
	
	import FullCalendar from '@fullcalendar/vue'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import interactionPlugin from '@fullcalendar/interaction'
	import timeGridPlugin from '@fullcalendar/timegrid'
	
	import config from '@/common/config.js'
	
	import timeObj  from '@/common/time.js'
	
	
	import timePanel  from '@/components/timeWindows/timePanel'
	
	  
	
	var _this=null;

	export default {
		name:'queTransferHospital',
		components:{timePanel,datePanel,doctorPanel},
	
		data(){
			return{
				
				keys:'',
				
				selHospitalId:'',
				isHospitalWindows:false,
				
				openTimeWindows:false,
				transferDate:null,
				
				
				isDoctorWindows:false,
				titleWindows:'',
				isWindows:false,
				timeList:[],
				
				selDoctorId:"",
				thisUser:config.getLoginInfo(),
				doctorTitle:this.DoctorTitle,
				dateList:timeObj.get7Days(),
				dateSelTime:timeObj.get7Days()[0],
				activeName:'doctor',
				// 每页数据量
				pageSize: 10,
				// 当前页
				pageCurrent: 1,
				// 数据总量
				total: 0,
				loading:false,
				
				doctorList:[],
				hospitalList:[],
				selectedIndexs:[],
				doctorTimeList:[],
				
				calendarOptions:{
					plugins: [dayGridPlugin,timeGridPlugin,  interactionPlugin],
					initialView: 'dayGridMonth',
					locale: 'zh',
					firstDay: 1,
					buttonText: {
					  today: "今天",
					  month: "月",
					  week: "周",
					  day: "日",
					  // list: "列表",
					},
					headerToolbar: {
					  left: 'prev,next today',
					  center: 'title',
					  right: 'dayGridMonth,timeGridWeek,timeGridDay',
					},
					weekNumberCalculation: 'ISO',
					nowIndicator: true,
					height:500,
					validRange: this.validRange,  //设置可显示的总日期范围
					eventTimeFormat: { // 在每个事件上显示的时间的格式
						hour: 'numeric', // numeric:2022,2-digit:22
						minute: '2-digit',
						meridiem: false,
						hour12: false // 设置时间为24小时
					},
					customButtons: {
						next: {click: this.nextClick},
						prev: {click: this.prevClick}
					},
					editable: false, //允许拖动缩放，不写默认就是false
					overlap: false, //允许时间重叠，即可以与其他事件并存，不写默认就是false
					events: [], //背景色 (添加相同时间的背景色时颜色会重叠)
					datesSet: this.datesSet, //日期渲染；修改日期范围后触发
					eventClick: this.handleEventClick, //点击日程触发
					dateClick: this.handleDateClick, //点击日期触发
					eventDrop: this.calendarEventDropOrResize, //拖动事件触发
					eventResize: this.calendarEventDropOrResize, //缩放事件触发
					displayEventTime: false, //不显示具体时间
				}
		  }
		},
		created() {
			_this=this;
			this.init("rem");
		},
		watch:{
			activeName(val){
				this.init("rem");
				console.log("我的变量"+val)
				
			}
			
		},
		methods:{
			onDataSubmit(){
				if(this.transferDate){
					      this.$router.push('/addTransferHospital?doctorId='+
						this.selDoctorId+"&date="+timeObj.miaoTofen(this.transferDate,"YYYY-MM-DD"));

					// uni.redirectTo({
					// 	url:'/pages/appointment/addTransferHospital/addTransferHospital?doctorId='+
					// 	this.selDoctorId+"&date="+timeObj.miaoTofen(this.transferDate,"YYYY-MM-DD")
					// })
				}else{
					this.$message({  message: '请选择日期',type: 'warning' });
				}
				
			},
			selHospital(item){
				this.isHospitalWindows=true;
				this.selHospitalId=item.id;
				
			},
			search(){
				this.init("");
			},
			init(rem){
				if(this.activeName=="doctor"){this.getDataDoctor(rem)}
				if(this.activeName=="hospital"){this.getDataHospital(rem)}
			},
			
			//获取医生信息 transfer
			getDataDoctor(rem){
				var _this1=this;
				_this1.loading = true;
				if(rem??rem=='rem'){this.pageCurrent=1;}
				config.ajaxAw("/kongtangweishi/api/userInfo/getOnlineDoctor",
				{
					doctorDistrictId:config.doctorDistrictId,
					
					page:this.pageCurrent, keys:this.keys,
					isPage:true,
					transfer: true,
					isOnline: true
				},function(data){
					console.log("1",data)
					setTimeout(function(){
						_this1.doctorList=data.content;
						_this1.loading = false;
						
					},2000)
					_this1.total = data.totalElements;
				})
			},
			
			//获取医院信息
			getDataHospital(rem){
				
				var _this1=this;
				_this1.loading = true;
				if(rem??rem=='rem'){this.pageCurrent=1;}
				config.ajaxAw("/api/auxiliary/getDoctorHospitalList",
				{
					doctorDistrictId:config.doctorDistrictId,
					
					page:this.pageCurrent, keys:this.keys,
					isPage:true,
					transfer: true,
					isOnline: true
				},function(data){
					setTimeout(function(){
						_this1.hospitalList=data.content;
						_this1.loading = false;
					},2000)
					_this1.total = data.totalElements;
				});
				
			
			},
			
			getDateDoctor(item){
				this.dateSelTime=item;
				//debugger
				this.init("rem")
			},
			//选择了医生  在去选择时间
			selDoctorDate(item){				
				// this.titleWindows=item.name;
				// this.isDoctorWindows=true;
				this.selDoctorId=item.id;
				
				this.openTimeWindows=true;
			
				
			},
			//在医院进入选择了 医生
			selDoctor(obj){
				this.titleWindows=obj.data.name+"-"+obj.date;
				this.isWindows=true;
				this.selDoctorId=obj.data.id;
				this.timeList=obj.data.list;
			},
			//通过日期选择了医生
			selDoctorTime(item){
				this.titleWindows=item.name+"-"+this.dateSelTime.date;
				this.isWindows=true;
				this.selDoctorId=item.id;
				this.timeList=item.list;
			},
			
			//获取时间
			getDataDoctorTime(rem){
				var _this1=this;
				if(rem??rem=='rem'){this.pageCurrent=1;}
				_this1.loading = true;
				config.ajaxAw("/kongtangweishi/api/doctorOnlineTime/getDoctorTimeList",
				{
					doctorDistrictId:config.doctorDistrictId,
					
					page:this.pageCurrent, keys:this.keys,
					date:this.dateSelTime.dateY,
					isPage:true,
					consultation: true,
					isOnline: true
				},function(data){
					setTimeout(function(){
						_this1.doctorTimeList=data;
						_this1.loading = false;
						
						// for (var i = 0; i < _this1.doctorTimeList.length; i++) {
						// 	var  item=_this1.doctorTimeList[i];
							
						// 	var obj={ id:item.id,title:item.name+"-"+item.hospitalName,
						// 	start:item.date,end:item.date}
						// 	  _this1.calendarOptions.events.push(obj);
							
						// }
						
						
						
					},2000)
				
				});
				
				
			},
			
			getDoctorTitle(value){
				
				for (var i = 0; i < this.doctorTitle.length; i++) {
					var item=this.doctorTitle[i]
					
					if(i==value){
						return item.text;
					}
					
				}
				
				return "";
				
				
			},
			
			openHospitalWindows(val){
					this.isHospitalWindows=val;
			},
			
			// 分页触发
			change(e) {
				this.pageCurrent=e.current;
				this.init();
			   // this.getData(e.current)
			},
			//多选
			selectionChange(e) {
			    console.log(e.detail.index);
			    this.selectedIndexs = e.detail.index
			},
			openWindows(val){
				this.isWindows=val;
			},
			//选择医生后选择日期
			openDoctorWindows(vale){
				this.isDoctorWindows=vale;
				
			},
			//标签选择
			 handleClick(tab, event) {
			        console.log(tab, event);
			},
			// obj={ id,title,start,end}
			//   this.calendarOptions.events.push(obj);
			//获取时间
			addre(){
				var dat=new Date();
				var obj={ id:1,title:"2334",start:"2023-12-01",end:dat}
				  this.calendarOptions.events.push(obj);
				
			},
		    nextClick() {
				//debugger;
		      this.$refs.fullCalendar.getApi().next() // 将日历向前移动一步（例如，一个月或一周）。
		    },
		    prevClick() {
		      this.$refs.fullCalendar.getApi().prev() // 将日历后退一步（例如，一个月或一周）。
		    }
		}
		
		  
	}
</script>

<style>
	
	.time_panel{margin: 10px 20px;text-align: center}
	.time_panel_week{font-size: 12px;color: #999;}
	.time_panel_date{font-size: 16px;}
	.time_panel.primary{color: #409EFF;}
	.time_panel.primary .time_panel_week{color: #409EFF;}
	.uni-group{justify-content:left;	margin: 10px;}

</style>
